"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SHOPEE_PAYMENT_TRANSACTION = exports.GET_SHOPEE_PAYMENT_TRANSACTIONS_WITH_META = exports.GET_SHOPEE_PAYMENT_TRANSACTIONS = exports.UPDATE_SHOPEE_PAYMENT_TRANSACTION = exports.DELETE_SHOPEE_PAYMENT_TRANSACTION = exports.CREATE_SHOPEE_PAYMENT_TRANSACTION = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragment_1 = require("../fragment");
/**
 * Mutation
 */
exports.CREATE_SHOPEE_PAYMENT_TRANSACTION = (0, graphql_tag_1.gql) `
  mutation CreateShopeePaymentTransaction($payload: ShopeePaymentTransactionInput!) {
    createShopeePaymentTransaction(payload: $payload) {
      ...shopeePaymentTransaction
    }
  }
  ${fragment_1.SHOPEE_PAYMENT_TRANSACTION}
`;
exports.DELETE_SHOPEE_PAYMENT_TRANSACTION = (0, graphql_tag_1.gql) `
  mutation DeleteShopeePaymentTransaction($_id: String!) {
    deleteShopeePaymentTransaction(_id: $_id) {
      ...shopeePaymentTransaction
    }
  }
  ${fragment_1.SHOPEE_PAYMENT_TRANSACTION}
`;
exports.UPDATE_SHOPEE_PAYMENT_TRANSACTION = (0, graphql_tag_1.gql) `
  mutation UpdateShopeePaymentTransaction(
    $payload: ShopeePaymentTransactionUpdateInput!
    $params: FindOneShopeePaymentTransactionInput!
  ) {
    updateShopeePaymentTransaction(payload: $payload, params: $params) {
      ...shopeePaymentTransaction
    }
  }
  ${fragment_1.SHOPEE_PAYMENT_TRANSACTION}
`;
/**
 * Query
 */
exports.GET_SHOPEE_PAYMENT_TRANSACTIONS = (0, graphql_tag_1.gql) `
  query GetShopeePaymentTransactions(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: ShopeePaymentTransactionFilterInput
  ) {
    shopeePaymentTransactions(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...shopeePaymentTransaction
    }
  }
  ${fragment_1.SHOPEE_PAYMENT_TRANSACTION}
`;
exports.GET_SHOPEE_PAYMENT_TRANSACTIONS_WITH_META = (0, graphql_tag_1.gql) `
  query GetShopeePaymentTransactionsWithMeta(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: ShopeePaymentTransactionFilterInput
  ) {
    shopeePaymentTransactionsWithMeta(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...shopeePaymentTransaction
    }

    shopeePaymentTransactionMeta {
      total
    }
  }
  ${fragment_1.SHOPEE_PAYMENT_TRANSACTION}
`;
exports.GET_SHOPEE_PAYMENT_TRANSACTION = (0, graphql_tag_1.gql) `
  query GetShopeePaymentTransaction($id: String!) {
    shopeePaymentTransaction(id: $id) {
      _id
    }
  }
`;
