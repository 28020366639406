"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SHOPEE_PROMOTIONAL_PROGRAMS_WITH_META = exports.GET_SHOPEE_PROMOTIONAL_PROGRAMS = exports.UPDATE_SHOPEE_PROMOTIONAL_PROGRAM = exports.DELETE_SHOPEE_PROMOTIONAL_PROGRAM = exports.CREATE_SHOPEE_PROMOTIONAL_PROGRAM = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragment_1 = require("../fragment");
/**
 * Mutation
 */
exports.CREATE_SHOPEE_PROMOTIONAL_PROGRAM = (0, graphql_tag_1.gql) `
  mutation CreateShopeePromotionalProgram($payload: ShopeePromotionalProgramInput!) {
    createShopeePromotionalProgram(payload: $payload) {
      ...shopeePromotionalProgram
    }
  }
  ${fragment_1.SHOPEE_PROMOTIONAL_PROGRAM}
`;
exports.DELETE_SHOPEE_PROMOTIONAL_PROGRAM = (0, graphql_tag_1.gql) `
  mutation DeleteShopeePromotionalProgram($_id: String!) {
    deleteShopeePromotionalProgram(_id: $_id) {
      ...shopeePromotionalProgram
    }
  }
  ${fragment_1.SHOPEE_PROMOTIONAL_PROGRAM}
`;
exports.UPDATE_SHOPEE_PROMOTIONAL_PROGRAM = (0, graphql_tag_1.gql) `
  mutation UpdateShopeePromotionalProgram(
    $payload: ShopeePromotionalProgramUpdateInput!
    $params: FindOneShopeePromotionalProgramInput!
  ) {
    updateShopeePromotionalProgram(payload: $payload, params: $params) {
      ...shopeePromotionalProgram
    }
  }
  ${fragment_1.SHOPEE_PROMOTIONAL_PROGRAM}
`;
/**
 * Query
 */
exports.GET_SHOPEE_PROMOTIONAL_PROGRAMS = (0, graphql_tag_1.gql) `
  query GetShopeePromotionalPrograms(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: ShopeePromotionalProgramFilterInput
  ) {
    shopeePromotionalPrograms(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...shopeePromotionalProgram
    }
  }
  ${fragment_1.SHOPEE_PROMOTIONAL_PROGRAM}
`;
exports.GET_SHOPEE_PROMOTIONAL_PROGRAMS_WITH_META = (0, graphql_tag_1.gql) `
  query GetShopeePromotionalProgramsWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: ShopeePromotionalProgramFilterInput
  ) {
    shopeePromotionalProgramsWithMeta(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...shopeePromotionalProgram
    }

    shopeePromotionalProgramMeta {
      total
    }
  }
  ${fragment_1.SHOPEE_PROMOTIONAL_PROGRAM}
`;
