"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LAZADA_TRANSACTION_FEE = void 0;
const graphql_tag_1 = require("graphql-tag");
exports.LAZADA_TRANSACTION_FEE = (0, graphql_tag_1.gql) `
  fragment lazadaTransactionFee on LazadaTransactionFee {
    _id
    name
    fee
    updatedAt
    createdAt
  }
`;
