"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHOPEE_PAYMENT_TRANSACTION = exports.SHOPEE_PROMOTIONAL_PROGRAM = void 0;
const graphql_tag_1 = require("graphql-tag");
exports.SHOPEE_PROMOTIONAL_PROGRAM = (0, graphql_tag_1.gql) `
  fragment shopeePromotionalProgram on ShopeePromotionalProgram {
    _id
    name
    percent
    maxCap
    isActive
    updatedAt
    createdAt
    shopId
  }
`;
exports.SHOPEE_PAYMENT_TRANSACTION = (0, graphql_tag_1.gql) `
  fragment shopeePaymentTransaction on ShopeePaymentTransaction {
    _id
    name
    percent
    updatedAt
    createdAt
  }
`;
