"use strict";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProductCostModalSelectType = exports.StockProductDocumentType = exports.StockProductDocumentTransactionAction = exports.StockProductDocumentStatus = exports.SortDirection = exports.SalesLeadStatus = exports.ProductType = exports.ProductStatus = exports.PrFormPaymentType = exports.AddMaterialMode = void 0;
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
//==============================================================
// START Enums and Input Objects
//==============================================================
var AddMaterialMode;
(function (AddMaterialMode) {
    AddMaterialMode["append"] = "append";
    AddMaterialMode["replace"] = "replace";
})(AddMaterialMode || (exports.AddMaterialMode = AddMaterialMode = {}));
var PrFormPaymentType;
(function (PrFormPaymentType) {
    PrFormPaymentType["NORMAL"] = "NORMAL";
    PrFormPaymentType["URGENT"] = "URGENT";
})(PrFormPaymentType || (exports.PrFormPaymentType = PrFormPaymentType = {}));
var ProductStatus;
(function (ProductStatus) {
    ProductStatus["ACTIVE"] = "ACTIVE";
    ProductStatus["DRAFT"] = "DRAFT";
    ProductStatus["INACTIVE"] = "INACTIVE";
})(ProductStatus || (exports.ProductStatus = ProductStatus = {}));
var ProductType;
(function (ProductType) {
    ProductType["BUNDLE"] = "BUNDLE";
    ProductType["CONVERTIBLE"] = "CONVERTIBLE";
    ProductType["FG_G"] = "FG_G";
    ProductType["NORMAL"] = "NORMAL";
    ProductType["RAW_MATERIAL"] = "RAW_MATERIAL";
    ProductType["RAW_MATERIAL_G"] = "RAW_MATERIAL_G";
    ProductType["REF"] = "REF";
    ProductType["SET"] = "SET";
    ProductType["SUB"] = "SUB";
    ProductType["WIP"] = "WIP";
    ProductType["WIP_G"] = "WIP_G";
})(ProductType || (exports.ProductType = ProductType = {}));
var SalesLeadStatus;
(function (SalesLeadStatus) {
    SalesLeadStatus["ASK_QUESTION"] = "ASK_QUESTION";
    SalesLeadStatus["BLOCKED"] = "BLOCKED";
    SalesLeadStatus["BOUGHT"] = "BOUGHT";
    SalesLeadStatus["BOUGHT_OTHER_CHANNEL"] = "BOUGHT_OTHER_CHANNEL";
    SalesLeadStatus["DRAFT"] = "DRAFT";
    SalesLeadStatus["LINE_MENU"] = "LINE_MENU";
    SalesLeadStatus["WAIT_TO_DECIDE"] = "WAIT_TO_DECIDE";
})(SalesLeadStatus || (exports.SalesLeadStatus = SalesLeadStatus = {}));
var SortDirection;
(function (SortDirection) {
    SortDirection["asc"] = "asc";
    SortDirection["desc"] = "desc";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
var StockProductDocumentStatus;
(function (StockProductDocumentStatus) {
    StockProductDocumentStatus["CANCELLED"] = "CANCELLED";
    StockProductDocumentStatus["DONE"] = "DONE";
    StockProductDocumentStatus["IN_PROGRESS"] = "IN_PROGRESS";
})(StockProductDocumentStatus || (exports.StockProductDocumentStatus = StockProductDocumentStatus = {}));
var StockProductDocumentTransactionAction;
(function (StockProductDocumentTransactionAction) {
    StockProductDocumentTransactionAction["DEPOSIT"] = "DEPOSIT";
    StockProductDocumentTransactionAction["TRANSFER"] = "TRANSFER";
    StockProductDocumentTransactionAction["WITHDRAW"] = "WITHDRAW";
})(StockProductDocumentTransactionAction || (exports.StockProductDocumentTransactionAction = StockProductDocumentTransactionAction = {}));
var StockProductDocumentType;
(function (StockProductDocumentType) {
    StockProductDocumentType["ADJUSTMENT"] = "ADJUSTMENT";
    StockProductDocumentType["DEPOSIT"] = "DEPOSIT";
    StockProductDocumentType["MANUFACTURE"] = "MANUFACTURE";
    StockProductDocumentType["TRANSFER"] = "TRANSFER";
    StockProductDocumentType["WITHDRAW"] = "WITHDRAW";
})(StockProductDocumentType || (exports.StockProductDocumentType = StockProductDocumentType = {}));
/**
 * Update types for a product cost
 */
var UpdateProductCostModalSelectType;
(function (UpdateProductCostModalSelectType) {
    UpdateProductCostModalSelectType["CALCULATE_AVERAGE"] = "CALCULATE_AVERAGE";
    UpdateProductCostModalSelectType["CUSTOM"] = "CUSTOM";
    UpdateProductCostModalSelectType["FROM_PRODUCT_AVERAGE"] = "FROM_PRODUCT_AVERAGE";
    UpdateProductCostModalSelectType["NO_UPDATE"] = "NO_UPDATE";
    UpdateProductCostModalSelectType["SAME_WITH_NEW_COST"] = "SAME_WITH_NEW_COST";
})(UpdateProductCostModalSelectType || (exports.UpdateProductCostModalSelectType = UpdateProductCostModalSelectType = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
