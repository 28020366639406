"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LAZADA_TRANSACTION_FEE = exports.GET_LAZADA_TRANSACTION_FEES_WITH_META = exports.GET_LAZADA_TRANSACTION_FEES = exports.UPDATE_LAZADA_TRANSACTION_FEE = exports.DELETE_LAZADA_TRANSACTION_FEE = exports.CREATE_LAZADA_TRANSACTION_FEE = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragment_1 = require("../fragment");
/**
 * Mutation
 */
exports.CREATE_LAZADA_TRANSACTION_FEE = (0, graphql_tag_1.gql) `
  mutation CreateLazadaTransactionFee($payload: LazadaTransactionFeeInput!) {
    createLazadaTransactionFee(payload: $payload) {
      ...lazadaTransactionFee
    }
  }
  ${fragment_1.LAZADA_TRANSACTION_FEE}
`;
exports.DELETE_LAZADA_TRANSACTION_FEE = (0, graphql_tag_1.gql) `
  mutation DeleteLazadaTransactionFee($_id: String!) {
    deleteLazadaTransactionFee(_id: $_id) {
      ...lazadaTransactionFee
    }
  }
  ${fragment_1.LAZADA_TRANSACTION_FEE}
`;
exports.UPDATE_LAZADA_TRANSACTION_FEE = (0, graphql_tag_1.gql) `
  mutation UpdateLazadaTransactionFee(
    $payload: LazadaTransactionFeeUpdateInput!
    $params: FindOneLazadaTransactionFeeInput!
  ) {
    updateLazadaTransactionFee(payload: $payload, params: $params) {
      ...lazadaTransactionFee
    }
  }
  ${fragment_1.LAZADA_TRANSACTION_FEE}
`;
/**
 * Query
 */
exports.GET_LAZADA_TRANSACTION_FEES = (0, graphql_tag_1.gql) `
  query GetLazadaTransactionFees(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: LazadaTransactionFeeFilterInput
  ) {
    lazadaTransactionFees(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...lazadaTransactionFee
    }
  }
  ${fragment_1.LAZADA_TRANSACTION_FEE}
`;
exports.GET_LAZADA_TRANSACTION_FEES_WITH_META = (0, graphql_tag_1.gql) `
  query GetLazadaTransactionFeesWithMeta(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: LazadaTransactionFeeFilterInput
  ) {
    lazadaTransactionFeesWithMeta(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...lazadaTransactionFee
    }

    lazadaTransactionFeesMeta {
      total
    }
  }
  ${fragment_1.LAZADA_TRANSACTION_FEE}
`;
exports.GET_LAZADA_TRANSACTION_FEE = (0, graphql_tag_1.gql) `
  query GetLazadaTransactionFee($id: String!) {
    lazadaTransactionFee(id: $id) {
      ...lazadaTransactionFee
    }
  }
`;
