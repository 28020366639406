"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./graphql/audit-log/graphql"), exports);
__exportStar(require("./graphql/auth/graphql"), exports);
__exportStar(require("./graphql/auth0/graphql"), exports);
__exportStar(require("./graphql/picking-list/graphql"), exports);
__exportStar(require("./graphql/report/graphql"), exports);
__exportStar(require("./graphql/reserve-order/graphql"), exports);
__exportStar(require("./graphql/role/graphql"), exports);
__exportStar(require("./graphql/saleslead/graphql"), exports);
__exportStar(require("./graphql/stock/graphql"), exports);
__exportStar(require("./graphql/workspace/graphql"), exports);
__exportStar(require("./graphql/workspace/booth-location/graphql"), exports);
__exportStar(require("./graphql/product"), exports);
__exportStar(require("./graphql/product-tracking/graphql"), exports);
__exportStar(require("./graphql/product-tracking-label/graphql"), exports);
__exportStar(require("./graphql/order"), exports);
__exportStar(require("./graphql/upload-url/graphql"), exports);
__exportStar(require("./graphql/kerry-api/graphql"), exports);
__exportStar(require("./graphql/notifications/graphql"), exports);
__exportStar(require("./graphql/staff/graphql"), exports);
__exportStar(require("./graphql/bank-account/graphql"), exports);
__exportStar(require("./graphql/trcloud-source/graphql"), exports);
__exportStar(require("./graphql/customer-profile/graphql"), exports);
__exportStar(require("./graphql/trcloud-log/graphql"), exports);
__exportStar(require("./graphql/shopee-config"), exports);
__exportStar(require("./graphql/lazada-config"), exports);
__exportStar(require("./graphql/order-return/graphql"), exports);
__exportStar(require("./graphql/stock-product/graphql"), exports);
__exportStar(require("./graphql/stock-product-category/graphql"), exports);
__exportStar(require("./graphql/stock-product-qrcode/graphql"), exports);
__exportStar(require("./graphql/stock-product-transaction/graphql"), exports);
__exportStar(require("./graphql/stock-product-warehouse/graphql"), exports);
__exportStar(require("./graphql/stock-product-user/graphql"), exports);
__exportStar(require("./graphql/stock-product-preset/graphql"), exports);
__exportStar(require("./graphql/stock-product-document/graphql"), exports);
__exportStar(require("./graphql/accounting-order/graphql"), exports);
__exportStar(require("./graphql/technical-data-sheet/graphql"), exports);
__exportStar(require("./graphql/purchase-requisition/graphql"), exports);
__exportStar(require("./graphql/purchase-order/graphql"), exports);
__exportStar(require("./graphql/currency/graphql"), exports);
__exportStar(require("./graphql/supplier-profile/graphql"), exports);
__exportStar(require("./graphql/department/graphql"), exports);
__exportStar(require("./graphql/sales-commission/graphql"), exports);
__exportStar(require("./graphql/working-hour/graphql"), exports);
__exportStar(require("./graphql/np-order/graphql"), exports);
__exportStar(require("./graphql/delivery-fleet/graphql"), exports);
__exportStar(require("./graphql/wages-report/graphql"), exports);
__exportStar(require("./graphql/vehicle/graphql"), exports);
__exportStar(require("./graphql/stock-card"), exports);
__exportStar(require("./graphql/supplier-product/graphql"), exports);
__exportStar(require("./graphql/task/graphql"), exports);
__exportStar(require("./__generated__/types"), exports);
__exportStar(require("./graphql/production-line/graphql"), exports);
__exportStar(require("./graphql/production-line-unit/graphql"), exports);
